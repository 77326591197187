<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$t('deviceGateway.editDevice')"
    width="1500px"
    top="3vh"
    @close="$reset('form')">
    <el-form
      ref="form"
      :label-width="$l('elevatorNetApply.labelWidth','120px')"
      :model="model">
      <div v-if="model.id > 0" class="vm-separate">
        <el-form-item :label="$t('elevator.registerCode')">
          <el-input v-model.trim="model.regNo" disabled=""></el-input>
        </el-form-item>
        <div></div>
        <div></div>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.dtuCode')" prop="inshnId" :rules="$rule.notNull">
          <el-input v-model.trim="model.inshnId" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.infraredType')" prop="inshnIo" :rules="$rule.notNull">
          <el-select
            v-model="model.inshnIo"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 100%">
            <el-option
              v-for="item in inshnIoList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.elevatorSpeed')" prop="inshnSpeed" :rules="$rule.notNull">
          <el-input v-model.trim="model.inshnSpeed" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.carDoorType')" prop="inshnDoorType" :rules="$rule.notNull">
          <el-select
            v-model="model.inshnDoorType"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 100%">
            <el-option
              v-for="item in inshnDoorTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.judgmentsNumber')" prop="inshnDoorCnt" :rules="$rule.notNull">
          <el-input type="number" v-model.trim="model.inshnDoorCnt" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.openDoorTime')" prop="inshnDoorTime" :rules="$rule.notNull">
          <el-input type="number" v-model.trim="model.inshnDoorTime" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.horizontalSpacing')" prop="inshnNextTime" :rules="$rule.notNull">
          <el-input type="number" v-model.trim="model.inshnNextTime" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.simICCID')" prop="iccid">
          <el-input v-model.trim="model.iccid" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.phone')" prop="inshnRegtel" :rules="$rule.notNull">
          <el-input v-model.trim="model.inshnRegtel" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.sensor')" prop="inshnFiltering" :rules="$rule.notNull">
          <el-select
            v-model="model.inshnFiltering"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 100%">
            <el-option
              v-for="item in inshnFilteringList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.networkFormat')" prop="inshnNetctype" :rules="$rule.notNull">
          <el-select
            v-model="model.inshnNetctype"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 100%">
            <el-option
              v-for="item in inshnNetctypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <div></div>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$t("elevatorNetApply.cancel")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$t("elevatorNetApply.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        model: {
          id: 0,
          inshnId: "",
          inshnIo: "",
          inshnSpeed: "",
          inshnDoorType: "",
          inshnDoorCnt: "",
          inshnDoorTime: "",
          inshnNextTime: "",
          inshnRegtel: "",
          iccid: "",
          inshnFiltering: "",
          inshnNetctype: "",
          regNo: "",
        },
        inshnIoList: [{value: 0, label: this.$t("deviceGateway.normallyClose")}, {value: 1, label: this.$t("deviceGateway.normallyOpen")}],
        inshnDoorTypeList: [{value: 0, label: this.$t("deviceGateway.singleDoor")}, {value: 1, label: this.$t("deviceGateway.doubleDoor")}, {value: 2, label: this.$t("deviceGateway.smoke")}, {
          value: 3,
          label: this.$t("deviceGateway.openDoorInPlace"),
        }],
        inshnFilteringList: [{value: 10, label: this.$t("deviceGateway.other")}, {value: 3, label: this.$t("deviceGateway.uModel")}],
        inshnNetctypeList: [{value: 0, label: this.$t("deviceGateway.wired")}, {value: 1, label: "2G"}, {value: 2, label: "3G"}, {
          value: 3,
          label: "4G",
        }],
      };
    },
    methods: {
      open(id, regNo) {
        this.model.id = id;
        this.dialogVisible = true;
        if (this.model.id) {
          this.model.regNo = regNo;
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("deviceGatewayInsh/" + this.model.id)
          .then(({data}) => {
            this.$assign(this.model, data);
          });
      },
      handleSubmit() {
        if (this.model.inshnRegtel) {
          let rule = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/;
          if (!rule.test(this.model.inshnRegtel)) {
            return this.$message.error(this.$t("user.validNumberNumber"));
          }
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("deviceGatewayInsh", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
